import React from 'react';

const Items = ({ image, title, subTitle }) => {
    return (
        <>
            {/*PC*/}
            <div className="vueEarnCommunityBox">
                <div className="vueEarnCommunityImg">
                    <img
                        src={image}
                        alt="vue 2 earn contents icon"
                        width="100%"
                    />
                </div>
                <div className="communityTextBox">
                    <span className="title">{title}</span>
                    <span className="subTitle">{subTitle}</span>
                </div>
            </div>
        </>
    );
};

export default Items;
