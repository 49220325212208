import React, { useCallback, useState } from 'react';

const SendMessage = ({ handleSendMessage }) => {
    const [sendHover, setSendHover] = useState(false);
    const handleSendHover = useCallback(() => {
        setSendHover(!sendHover);
    }, [sendHover]);
    return (
        // <>
        //     {sendHover ? (
        //         <button
        //             className="SendMessageActive"
        //             onMouseEnter={handleSendHover}
        //             onMouseLeave={handleSendHover}
        //             onClick={handleSendMessage}
        //         >
        //             Send Message
        //         </button>
        //     ) : (
        //         <button
        //             className="SendMessage"
        //             onMouseEnter={handleSendHover}
        //             onMouseLeave={handleSendHover}
        //         >
        //             <div className="subBackground">Send Message</div>
        //         </button>
        //     )}
        // </>
        <>
            {handleSendMessage ? (
                <>
                    {sendHover ? (
                        <button
                            className="SendMessageActive_shadow"
                            onClick={handleSendMessage}
                            onMouseLeave={handleSendHover}
                            onMouseEnter={handleSendHover}
                        >
                            <div className="subBackground">
                                <span>Send Message</span>
                            </div>
                        </button>
                    ) : (
                        <button
                            className="SendMessage_shadow"
                            onMouseLeave={handleSendHover}
                            onMouseEnter={handleSendHover}
                        >
                            Send Message
                        </button>
                    )}
                </>
            ) : (
                <>
                    {sendHover ? (
                        <button
                            className="SendMessageActive"
                            onClick={handleSendMessage}
                            onMouseLeave={handleSendHover}
                            onMouseEnter={handleSendHover}
                        >
                            <div className="subBackground">Send Message</div>
                        </button>
                    ) : (
                        <button
                            className="SendMessage"
                            onMouseLeave={handleSendHover}
                            onMouseEnter={handleSendHover}
                        >
                            Send Message
                        </button>
                    )}
                </>
            )}
        </>
    );
};

export default SendMessage;
