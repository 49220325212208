import React from 'react';
import { Link } from 'react-scroll';

// import Logo from 'assets/images/Logo.png';
import Logo from 'assets/images/vue_icon.png';
import Lang from './Lang';
import CreateNFT from 'shared/CreateNFT';

const SideMenu = () => {
    return (
        <div className="sideMenu">
            <div className="topContent">
                <div className="labelLogo">
                    <img src={Logo} alt="LabelLogo" width="100%" />
                </div>
                <Lang />
            </div>
            <Link activeClass="sideActive" to="about" spy={true} smooth={true}>
                <span>About</span>
            </Link>
            <Link activeClass="sideActive" to="value" spy={true} smooth={true}>
                <span>Value</span>
            </Link>
            <Link activeClass="sideActive" to="token" spy={true} smooth={true}>
                <span>Token</span>
            </Link>
            <Link
                activeClass="sideActive"
                to="roadMap"
                spy={true}
                smooth={true}
            >
                <span>Roadmap</span>
            </Link>
            <Link
                activeClass="sideActive"
                to="partners"
                spy={true}
                smooth={true}
            >
                <span>Partners</span>
            </Link>
            <Link activeClass="sideActive" to="team" spy={true} smooth={true}>
                <span>Team</span>
            </Link>
            <Link
                activeClass="sideActive"
                to="contact"
                spy={true}
                smooth={true}
            >
                <span>Contact</span>
            </Link>
            <Link
                activeClass="sideActive"
                to="learnMore"
                spy={true}
                smooth={true}
            >
                <span>LearnMore</span>
            </Link>
            <CreateNFT />
        </div>
    );
};

export default SideMenu;
