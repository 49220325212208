import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Layout from 'layout/Layout';
import MainBannerComponent from 'components/MainBannerComponent';
import ValueationComponent from 'components/ValueationComponent';
// import BannerComponent from 'components/BannerComponent';
import RoadmapComponent from 'components/RoadmapComponent';
import PartnersComponent from 'components/PartnersComponent';
import TeamComponent from 'components/TeamComponent';
// import AdviserComponent from 'components/AdviserComponent';
import ContactComponent from 'components/ContactComponent';
import StoryComponent from '../components/StoryComponent';
import MetaverseComponent from '../components/MetaverseComponent';
// import ValuationTitleComponent from '../components/ValuationTitleComponent';
import VueTokenComponent from '../components/VueTokenComponent';
// import IllegalComponent from '../components/IllegalComponent';
import VueExplanComponent from '../components/VueExplanComponent';
import VueEarnComponent from '../components/VueEarnComponent';
// import NoUrcodeComponent from '../components/NoUrcodeComponent';
// import YesUrcodeComponent from '../components/YesUrcodeComponent';

const MainPage = () => {
    const history = useHistory();
    const { lang } = useParams();
    useEffect(() => {
        if (lang !== 'en' && lang !== 'ko') {
            history.push('/');
        }
    }, [lang, history]);
    return (
        <Layout
            // whitePaper={`https://stea.s3.ap-northeast-2.amazonaws.com/whitepaper/211102+stea+white+paper.pdf`}
            whitePaper={`https://docs.vue.foundation/`}
        >
            <MainBannerComponent lang={lang} />
            {/*<NoUrcodeComponent lang={lang} />*/}
            <StoryComponent lang={lang} />
            {/*<YesUrcodeComponent lang={lang} />*/}
            {/*<IllegalComponent lang={lang} />*/}
            <VueExplanComponent lang={lang} />
            <VueEarnComponent lang={lang} />
            <MetaverseComponent lang={lang} />
            {/*<ValuationTitleComponent />*/}
            <ValueationComponent
                lang={lang}
                video1={`https://stea.s3.ap-northeast-2.amazonaws.com/video/video1.mp4`}
                video2={`https://stea.s3.ap-northeast-2.amazonaws.com/video/video2.mp4`}
                video3={`https://stea.s3.ap-northeast-2.amazonaws.com/video/video3.mp4`}
            />
            <VueTokenComponent lang={lang} />
            {/*<BannerComponent />*/}
            <RoadmapComponent lang={lang} />
            <PartnersComponent lang={lang} />
            <TeamComponent lang={lang} />
            {/*<AdviserComponent />*/}
            <ContactComponent />
        </Layout>
    );
};

export default MainPage;
