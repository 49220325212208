import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import leftImg from 'assets/images/vueToken_incineration_img.png';
import rightImg from 'assets/images/vueToken_assignment_img.png';
import bottomImg from 'assets/images/vueToken_economic_img.png';
import leftImg_en from 'assets/images/vueToken_incineration_img(en).png';
import rightImg_en from 'assets/images/vueToken_assignment_img(en).png';
import bottomImg_en from 'assets/images/vueToken_economic_img(en).png';

const VueTokenComponent = ({ lang }) => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(`${lang}`);
    }, [i18n, lang]);
    return (
        <div className="backgroundMargin" id="token">
            <div className="vueTokenContainer wrap">
                <div className="vueTokenTitle desktop">
                    <span className="title">{t('vueToken.desktop.title')}</span>
                    <span className="subTitle">
                        {t('vueToken.desktop.subTitle')}
                    </span>
                </div>
                <div className="vueTokenTitle mobile">
                    <span className="title">{t('vueToken.mobile.title')}</span>
                    <span className="subTitle">
                        {t('vueToken.mobile.subTitle')}
                    </span>
                </div>
                <div className="topImage">
                    <div className="topImageLeft">
                        <img
                            src={lang === 'ko' ? leftImg : leftImg_en}
                            alt="vueToken img"
                            width="100%"
                        />
                    </div>
                    <div className="topImageRight">
                        <img
                            src={lang === 'ko' ? rightImg : rightImg_en}
                            alt="vueToken img"
                            width="100%"
                        />
                    </div>
                </div>
                <div className="bottomImage">
                    <img
                        src={lang === 'ko' ? bottomImg : bottomImg_en}
                        alt="vueToken img"
                        width="100%"
                    />
                </div>
            </div>
        </div>
    );
};

export default VueTokenComponent;
