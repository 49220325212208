import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import langEn from './lang.en.json';
import langKo from './lang.ko.json';

const resources = {
    en: {
        translation: langEn,
    },
    ko: {
        translation: langKo,
    },
};

i18n.use(initReactI18next).init({
    resources: resources,
    // 초기 설정 언어
    lng: 'ko',
    fallbackLng: 'ko', // 감지된 Lng가 없는경우
    // debug: true,
    // defaultNS: 'translations',
});
