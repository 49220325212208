import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Items from './items';

import nemoImg from 'assets/images/patners_nemo_img.png';
import usebImg from 'assets/images/patners_useb_img.png';
import eoneImg from 'assets/images/patners_e1_img.png';
import imcloudImg from 'assets/images/patners_imcloud_img.png';
import xinovaImg from 'assets/images/patners_xinova_img.png';

import nemoImgMobile from 'assets/images/partners_nemo_img_mobile.png';
import usebImgMobile from 'assets/images/partners_useb_img_mobile.png';
import eoneImgMobile from 'assets/images/partners_e1_img_mobile.png';
import imcloudImgMobile from 'assets/images/partners_imcloud_img_mobile.png';
import xinovaImgMobile from 'assets/images/partners_xinova_img_mobile.png';

const PartnersComponent = ({ lang }) => {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        i18n.changeLanguage(`${lang}`);
    }, [i18n, lang]);
    return (
        <>
            <div className="partnersContainer wrap desktop" id="partners">
                <Items
                    companyImg={nemoImg}
                    title="KEY PARTNERS #1"
                    company={t('partners.desktop.nemo.company')}
                    companyEn="NEMOPARTNERS"
                    text={t('partners.desktop.nemo.comment')}
                />
                <Items
                    companyImg={usebImg}
                    title="KEY PARTNERS #2"
                    company={t('partners.desktop.useb.company')}
                    companyEn="USEB"
                    text={t('partners.desktop.useb.comment')}
                />
                <Items
                    companyImg={eoneImg}
                    title="KEY PARTNERS #3"
                    company={t('partners.desktop.eone.company')}
                    companyEn="E1"
                    text={t('partners.desktop.eone.comment')}
                />
                <Items
                    companyImg={imcloudImg}
                    title="KEY PARTNERS #4"
                    company={t('partners.desktop.imcloud.company')}
                    companyEn="IMCLOUD"
                    text={t('partners.desktop.imcloud.comment')}
                />
                <Items
                    companyImg={xinovaImg}
                    title="KEY PARTNERS #5"
                    company={t('partners.desktop.xinova.company')}
                    companyEn="XINOVA"
                    text={t('partners.desktop.xinova.comment')}
                />
                {/*            <Items*/}
                {/*                companyImg={nemoImg}*/}
                {/*                title="KEY PARTNERS #1"*/}
                {/*                company="네모파트너즈"*/}
                {/*                companyEn="NEMOPARTNERS"*/}
                {/*                text={`*/}
                {/*네모파트너즈는 10개국 13개 오피스에서*/}
                {/*기업대상 경영컨설팅 분야 Top Tier 기업으로,*/}
                {/*다양한 산업과 고객을 대상으로 800개 이상의 프로젝트를 수행했습니다.*/}
                {/*또한, 매출의 15%를 해외에서 벌어들이는*/}
                {/*지식산업수출의 선두주자입니다.*/}
                {/*        `}*/}
                {/*            />*/}
                {/*            <Items*/}
                {/*                companyImg={usebImg}*/}
                {/*                title="KEY PARTNERS #2"*/}
                {/*                company="유스비"*/}
                {/*                companyEn="USEB"*/}
                {/*                text={`*/}
                {/*유스비는 비대면 통합인증 솔루션을 개발하는 회사로,*/}
                {/*핀테크, 은행, 금융 파트너를 중심으로 */}
                {/*그에 맞는 비대면 본인인증 솔루션 (KYC, AML, AI 솔루션)*/}
                {/*관련 업무 제휴를 체결한 VUE의 파트너입니다.*/}
                {/*        `}*/}
                {/*            />*/}
                {/*            <Items*/}
                {/*                companyImg={eoneImg}*/}
                {/*                title="KEY PARTNERS #3"*/}
                {/*                company="이원정보기술"*/}
                {/*                companyEn="E1"*/}
                {/*                text={`*/}
                {/*이원정보기술은 IBM IIB, DatePower 등*/}
                {/*다양한 고성능 비즈니스 솔루션을 제공하는 IT 솔루션 기업으로,*/}
                {/*기술 및 연구 개발을 위한 업무 제휴를 체결한 VUE의 파트너입니다.*/}
                {/*        `}*/}
                {/*            />*/}
                {/*            <Items*/}
                {/*                companyImg={imcloudImg}*/}
                {/*                title="KEY PARTNERS #4"*/}
                {/*                company="아임클라우드"*/}
                {/*                companyEn="IMCLOUD"*/}
                {/*                text={`*/}
                {/*아임클라우드는 AI와 챗봇, 그리고 빅데이터 SW 플랫폼을*/}
                {/*제공하는 기업으로, 기술 및 연구 개발을 위한 업무 제휴를 체결한*/}
                {/*VUE의 파트너입니다.*/}
                {/*        `}*/}
                {/*            />*/}
                {/*            <Items*/}
                {/*                companyImg={xinovaImg}*/}
                {/*                title="KEY PARTNERS #5"*/}
                {/*                company="지노바"*/}
                {/*                companyEn="XINOVA"*/}
                {/*                text={`*/}
                {/*지노바는 제품 및 기술 이노베이션 서비스를 바탕으로*/}
                {/*특허와 기술 등 다양한 분야에*/}
                {/*엑세러레이팅, 컨설팅 등을 지원하는 글로벌 기업으로,*/}
                {/*레이블 기술의 고도화 및 상용화를 위한*/}
                {/*업무 제휴를 체결한 VUE의 파트너입니다.*/}
                {/*        `}*/}
                {/*            />*/}
            </div>
            <div className="partnersContainer mobile" id="partners">
                <Items
                    companyImg={nemoImgMobile}
                    title="KEY PARTNERS #1"
                    company={t('partners.mobile.nemo.company')}
                    companyEn="NEMOPARTNERS"
                    text={t('partners.mobile.nemo.comment')}
                />
                <Items
                    companyImg={usebImgMobile}
                    title="KEY PARTNERS #2"
                    company={t('partners.mobile.useb.company')}
                    companyEn="USEB"
                    text={t('partners.mobile.useb.comment')}
                />
                <Items
                    companyImg={eoneImgMobile}
                    title="KEY PARTNERS #3"
                    company={t('partners.mobile.eone.company')}
                    companyEn="E1"
                    text={t('partners.mobile.eone.comment')}
                />
                <Items
                    companyImg={imcloudImgMobile}
                    title="KEY PARTNERS #4"
                    company={t('partners.mobile.imcloud.company')}
                    companyEn="IMCLOUD"
                    text={t('partners.mobile.imcloud.comment')}
                />
                <Items
                    companyImg={xinovaImgMobile}
                    title="KEY PARTNERS #5"
                    company={t('partners.mobile.xinova.company')}
                    companyEn="XINOVA"
                    text={t('partners.mobile.xinova.comment')}
                />
            </div>
            <div className="partnersContainer tablet wrap" id="partners">
                <Items
                    companyImg={nemoImgMobile}
                    title="KEY PARTNERS #1"
                    company={t('partners.tablet.nemo.company')}
                    companyEn="NEMOPARTNERS"
                    text={t('partners.tablet.nemo.comment')}
                />
                <Items
                    companyImg={usebImgMobile}
                    title="KEY PARTNERS #2"
                    company={t('partners.tablet.useb.company')}
                    companyEn="USEB"
                    text={t('partners.tablet.useb.comment')}
                />
                <Items
                    companyImg={eoneImgMobile}
                    title="KEY PARTNERS #3"
                    company={t('partners.tablet.eone.company')}
                    companyEn="E1"
                    text={t('partners.tablet.eone.comment')}
                />
                <Items
                    companyImg={imcloudImgMobile}
                    title="KEY PARTNERS #4"
                    company={t('partners.tablet.imcloud.company')}
                    companyEn="IMCLOUD"
                    text={t('partners.tablet.imcloud.comment')}
                />
                <Items
                    companyImg={xinovaImgMobile}
                    title="KEY PARTNERS #5"
                    company={t('partners.tablet.xinova.company')}
                    companyEn="XINOVA"
                    text={t('partners.tablet.xinova.comment')}
                />
            </div>
        </>
    );
};

export default PartnersComponent;
