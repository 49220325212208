import React from 'react';

import menuIcon from 'assets/icons/Menu_gray.svg';
import logoSmall from 'assets/images/LogoSmall.png';
import Lang from './Lang';

const Navigation = ({ handleOpenMenu }) => {
    return (
        <div className="navigation">
            <div className="navigationContainer">
                <div className="menuIcon" onClick={handleOpenMenu}>
                    <img
                        src={menuIcon}
                        alt="menu img"
                        width="30px"
                        height="30px"
                    />
                </div>
                <div className="logoSmall">
                    <img
                        src={logoSmall}
                        alt="logo img"
                        width="75px"
                        height="28px"
                    />
                </div>
                <Lang mobile />
            </div>
        </div>
    );
};

export default Navigation;
