import React, { useEffect } from 'react';
import Items from './items';

import { useTranslation } from 'react-i18next';

import StoryIllegal_ko from 'assets/images/storyIllegal(ko).png';
import StoryOpenSea_ko from 'assets/images/storyOpenSea(ko).png';
import StoryIllegal_en from 'assets/images/storyIllegal(en).png';
import StoryOpenSea_en from 'assets/images/storyOpenSea(en).png';
import StoryOpenSeaMobile from 'assets/images/opensea_img_mobile.png';
import StoryIllegalMobile from 'assets/images/illegal_img_mobile.png';
import StoryOpenSeaMobile_en from 'assets/images/storyOpenSea_mobile_en.png';
import StoryIllegalMobile_en from 'assets/images/storyIllegal_mobile_en.png';

const StoryComponent = ({ lang }) => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(`${lang}`);
    }, [i18n, lang]);

    return (
        <>
            <div className="storyContainer wrap">
                <Items
                    redTitle={t('story.desktop.openSea.redTitle')}
                    title={t('story.desktop.openSea.title')}
                    subTitle={t('story.desktop.openSea.comment')}
                    // storyImg={storyLeft}
                    storyImg={lang === 'ko' ? StoryOpenSea_ko : StoryOpenSea_en}
                />
                <Items
                    redTitle={t('story.desktop.illegalFakeProducts.redTitle')}
                    title={t('story.desktop.illegalFakeProducts.title')}
                    subTitle={t('story.desktop.illegalFakeProducts.comment')}
                    storyImg={lang === 'ko' ? StoryIllegal_ko : StoryIllegal_en}
                />
            </div>
            <div className="storyContainer mobile wrap">
                <Items
                    title={t('story.mobile.openSea.title')}
                    subTitle={t('story.mobile.openSea.comment')}
                    // storyImg={storyLeft}
                    storyImg={
                        lang === 'ko'
                            ? StoryOpenSeaMobile
                            : StoryOpenSeaMobile_en
                    }
                />
                <Items
                    title={t('story.mobile.illegalFakeProducts.title')}
                    subTitle={t('story.mobile.illegalFakeProducts.comment')}
                    storyImg={
                        lang === 'ko'
                            ? StoryIllegalMobile
                            : StoryIllegalMobile_en
                    }
                />
            </div>
            <div className="storyContainer tablet wrap">
                <Items
                    title={t('story.tablet.openSea.title')}
                    subTitle={t('story.tablet.openSea.comment')}
                    // storyImg={storyLeft}
                    storyImg={
                        lang === 'ko'
                            ? StoryOpenSeaMobile
                            : StoryOpenSeaMobile_en
                    }
                />
                <Items
                    title={t('story.tablet.illegalFakeProducts.title')}
                    subTitle={t('story.tablet.illegalFakeProducts.comment')}
                    storyImg={
                        lang === 'ko'
                            ? StoryIllegalMobile
                            : StoryIllegalMobile_en
                    }
                />
            </div>
        </>
    );
};

export default StoryComponent;
