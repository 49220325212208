import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Items from './Items';

const ValuationComponent = ({ video1, video2, video3, lang }) => {
    // const [isMobile, setIsMobile] = useState(false);
    const { t, i18n } = useTranslation();

    // useEffect(() => {
    //     const screenMobile = window.matchMedia('screen and (max-width: 768px)');
    //     setIsMobile(screenMobile.matches);
    // }, []);
    useEffect(() => {
        i18n.changeLanguage(`${lang}`);
    }, [i18n, lang]);
    return (
        <div className="valueationContainer wrap">
            <div className="valueationTitleContainer desktop">
                <span className="title">{t('valueation.desktop.title')}</span>
                <span className="subTitle">
                    {t('valueation.desktop.subTitle')}
                </span>
            </div>
            <div className="valueationTitleContainer mobile">
                <span className="title">{t('valueation.mobile.title')}</span>
                <span className="subTitle">
                    {t('valueation.mobile.subTitle')}
                </span>
            </div>
            <div className="valueationDesktop">
                <Items
                    title={t('valueation.desktop.video1.title')}
                    subTitle={t('valueation.desktop.video1.subTitle')}
                    video={video1}
                />
                <Items
                    title={t('valueation.desktop.video2.title')}
                    subTitle={t('valueation.desktop.video2.subTitle')}
                    video={video2}
                    reverse
                />
                <Items
                    title={t('valueation.desktop.video3.title')}
                    subTitle={t('valueation.desktop.video3.subTitle')}
                    video={video3}
                />
            </div>
            <div className="valueationMobile">
                <Items
                    title={t('valueation.mobile.video1.title')}
                    subTitle={t('valueation.mobile.video1.subTitle')}
                    video={video1}
                    reverse
                />
                <Items
                    title={t('valueation.mobile.video2.title')}
                    subTitle={t('valueation.mobile.video2.subTitle')}
                    video={video2}
                    reverse
                />
                <Items
                    title={t('valueation.mobile.video3.title')}
                    subTitle={t('valueation.mobile.video3.subTitle')}
                    video={video3}
                    reverse
                />
            </div>
        </div>
    );
};

export default ValuationComponent;
