import React from 'react';

const Items = ({ redTitle, title, subTitle, storyImg }) => {
    return (
        <>
            <div className="storyContents">
                <div className="textContents">
                    <span className="redTitle">{redTitle}</span>
                    <span className="title">{title}</span>
                    <span className="subTitle">{subTitle}</span>
                </div>

                <div className="storyImage">
                    <img src={storyImg} alt="story img" width="100%" />
                </div>
            </div>
        </>
    );
};

export default Items;
