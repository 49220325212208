import React from 'react';

import TestComponent from "../Test";

const Table = () => {

    return (
        <>
            <TestComponent />
        </>
    );
};

export default Table;
