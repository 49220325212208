import React from 'react';

import Logo from 'assets/images/vue_icon.png';
import arrow from 'assets/icons/Haruki Icons.png';

const Footer = ({ whitePaper }) => {
    return (
        <div className="footerContainer" id="learnMore">
            <div className="footerContentBox">
                <div className="whitePaper">
                    <div className="labelLogo">
                        <img src={Logo} alt="LabelLogo" width="100%" />
                    </div>
                    <button className="whitePaperDown">
                        <a href={whitePaper} target="blank">
                            <span>WHITE PAPER</span>
                            <img src={arrow} alt="arrow icon" />
                        </a>
                    </button>
                </div>
                <div className="privacyPolicy">
                    <span className="footerTitle">PRIVACY POLICY</span>
                    <span className="content">© VUE. All rights reserved.</span>
                </div>
                <div className="contactUs">
                    <span className="footerTitle">CONTACT US</span>
                    <span className="content">info@vue.foundation</span>
                    {/*<span className="content">*/}
                    {/*    singapore singapore singapore singapore*/}
                    {/*</span>*/}
                </div>
                <div className="snsLink">
                    <span className="footerTitle">SNS LINK</span>
                    <div
                        className="sns twitter"
                        onClick={() =>
                            window.open('https://twitter.com/vue_foundation')
                        }
                    />
                    {/*<div className="sns telegram" />*/}
                    {/*<div className="sns discord" />*/}
                    {/*<div className="sns kakao" />*/}
                    <div
                        className="sns medium"
                        onClick={() =>
                            window.open(' https://medium.com/@info_vue')
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default Footer;
