import React from 'react';
import { NavLink } from 'react-router-dom';

const Lang = ({ mobile }) => {
    return (
        <div className={`langContainer ${mobile ? 'langMobile' : ''}`}>
            <NavLink to="/lang=ko" activeClassName="selected">
                KO
            </NavLink>
            <NavLink to="/lang=en" activeClassName="selected">
                EN
            </NavLink>
        </div>
    );
};

export default Lang;
