import React, { useEffect } from 'react';
import vueExplanImg from 'assets/images/vue_contents_image_x1.png';
import vueExplanImg_en from 'assets/images/vue_contents_image_x1(en).png';
import valueImg from 'assets/images/vue_value_contents_image_x1.png';
import valueImg_en from 'assets/images/vue_value_contents_image_x1(en).png';
import { useTranslation } from 'react-i18next';

const VueExplanComponent = ({ lang }) => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(`${lang}`);
    }, [i18n, lang]);

    return (
        <>
            <div className="vueExplanContents wrap" id="about">
                <div className="vueExplanText">
                    <span className="title">VUE</span>
                    <span className="subTitle">
                        {t('vueExplan.desktop.subTitle')}
                    </span>
                </div>
                <div className="vueExplanText mobile">
                    <span className="title">VUE</span>
                    <span className="subTitle mobile">
                        {t('vueExplan.mobile.subTitle')}
                    </span>
                </div>
                <div className="vueExplanImg">
                    <img
                        src={lang === 'ko' ? vueExplanImg : vueExplanImg_en}
                        alt="vue explan img"
                        width="100%"
                    />
                </div>
            </div>
            <div className="backgroundMargin" id="value">
                <div className="valueContents wrap">
                    <span className="value">Value</span>
                    <div className="valueTextContents">
                        <span className="title">
                            {t('vueValue.desktop.title')}
                        </span>
                        <span className="title mobile">
                            {t('vueValue.mobile.title')}
                        </span>
                        <span className="subTitle">
                            {t('vueValue.desktop.subTitle')}
                        </span>
                        <span className="subTitle mobile">
                            {t('vueValue.mobile.subTitle')}
                        </span>
                    </div>
                    <div className="vueValueImg">
                        <img
                            src={lang === 'ko' ? valueImg : valueImg_en}
                            alt="value explan img"
                            width="100%"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default VueExplanComponent;
