import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import vueEarnCommunityImg from 'assets/images/vueEarn_community_img.png';
import vueEarnFunImg from 'assets/images/vueEarn_fun_img.png';
import vueEarnReportImg from 'assets/images/vueEarn_report_img.png';
import vueEarnRewardImg from 'assets/images/vueEarn_reward_img.png';

import Items from './Items';

const VueEarnComponent = ({ lang }) => {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        i18n.changeLanguage(`${lang}`);
    }, [i18n, lang]);

    return (
        <>
            <div className="vueEarnContainer wrap">
                <div className="vueEarnText">
                    <span className="title">{t('vue2Earn.desktop.title')}</span>
                    <span className="title mobile">
                        {t('vue2Earn.mobile.title')}
                    </span>
                    <span className="subTitle">
                        {t('vue2Earn.desktop.subTitle')}
                    </span>
                    <span className="subTitle mobile">
                        {t('vue2Earn.mobile.subTitle')}
                    </span>
                </div>

                {/*PC*/}
                <div className="vueEarnContents">
                    <Items
                        image={vueEarnCommunityImg}
                        title={t('vue2Earn.desktop.community.title')}
                        subTitle={t('vue2Earn.desktop.community.subTitle')}
                    />
                    <Items
                        image={vueEarnFunImg}
                        title={t('vue2Earn.desktop.fun.title')}
                        subTitle={t('vue2Earn.desktop.fun.subTitle')}
                    />
                    <Items
                        image={vueEarnReportImg}
                        title={t('vue2Earn.desktop.report.title')}
                        subTitle={t('vue2Earn.desktop.report.subTitle')}
                    />
                    <Items
                        image={vueEarnRewardImg}
                        title={t('vue2Earn.desktop.reward.title')}
                        subTitle={t('vue2Earn.desktop.reward.subTitle')}
                    />
                </div>

                {/* mobile */}
                <div className="vueEarnContents mobile">
                    <Items
                        image={vueEarnCommunityImg}
                        title={t('vue2Earn.mobile.community.title')}
                        subTitle={t('vue2Earn.mobile.community.subTitle')}
                    />
                    <Items
                        image={vueEarnFunImg}
                        title={t('vue2Earn.mobile.fun.title')}
                        subTitle={t('vue2Earn.mobile.fun.subTitle')}
                    />
                    <Items
                        image={vueEarnReportImg}
                        title={t('vue2Earn.mobile.report.title')}
                        subTitle={t('vue2Earn.mobile.report.subTitle')}
                    />
                    <Items
                        image={vueEarnRewardImg}
                        title={t('vue2Earn.mobile.reward.title')}
                        subTitle={t('vue2Earn.mobile.reward.subTitle')}
                    />
                </div>
            </div>
        </>
    );
};

export default VueEarnComponent;
