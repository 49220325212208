import React, { useEffect } from 'react';

// import RoadmapTest from "./RoadmapTest";

import roadMap from 'assets/images/roadmap_image_x1.png';
import roadMap_en from 'assets/images/roadmap_image_x1(en).png';
import roadmapImage from 'assets/images/roadmap_image_x1_mobile.png';
import roadmapImage_en from 'assets/images/roadmap_image_x1_mobile(en).png';
import { useTranslation } from 'react-i18next';

const RoadmapComponent = ({ lang }) => {
    const { i18n } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(`${lang}`);
    }, [i18n, lang]);

    return (
        <>
            <div className="roadmapContainer wrap" id="roadMap">
                <span className="title">ROADMAP</span>
                <div className="roadmapImageMobile">
                    <img
                        src={lang === 'ko' ? roadmapImage : roadmapImage_en}
                        alt="roadmap img"
                        width="100%"
                    />
                </div>
                <div className="roadmapImageDesktop">
                    <img
                        src={lang === 'ko' ? roadMap : roadMap_en}
                        alt="roadMapImg"
                        width="100%"
                    />
                </div>
                {/*<img src={roadMap} alt="roadMapImg" width="100%" />*/}
                {/*<div className="p_relative roadmap">*/}
                {/*  <div className="roadmapLine" />*/}
                {/*  <div className="roadmapItem one">*/}
                {/*    <span className="year">2021Y 4Q</span>*/}
                {/*    <div className="dot" />*/}
                {/*    <div className="detail">*/}
                {/*      <span>WATERLABEL 개발 착수 </span>*/}
                {/*      <span>WATERLABEL 특허 출원</span>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
            </div>
            {/*<RoadmapTest />*/}
        </>
    );
};

export default RoadmapComponent;
