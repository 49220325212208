import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import metaverseImg from 'assets/images/metaverse_img_x1.png';

const MetaverseComponent = ({ lang }) => {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        i18n.changeLanguage(`${lang}`);
    }, [i18n, lang]);
    return (
        <div className="backgroundMargin">
            <div className="metaverseContainer wrap">
                <span className="title">{t('metamask.desktop.title')}</span>
                <span className="title mobile">
                    {t('metamask.mobile.title')}
                </span>
                <span className="subTitle">
                    {t('metamask.desktop.subTitle')}
                </span>
                <span className="subTitle mobile">
                    {t('metamask.mobile.subTitle')}
                </span>

                <div className="metaverseImg">
                    <img src={metaverseImg} alt="metaverse img" width="100%" />
                </div>
            </div>
        </div>
    );
};

export default MetaverseComponent;
